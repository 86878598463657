const images = [{
	path: '/images/compressed/Alaska/1-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/1-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/2-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/2-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/3-IMG_4551_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/3-IMG_4551_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/4-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/4-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/5_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/5_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/6-min-img.jpg',
	thumb_path: '/images/thumbnails/Alaska/6-min-img.jpg'
} , 
{
	path: '/images/compressed/Alaska/7-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/7-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/8_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/8_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/9-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/9-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/91_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/91_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/92_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/92_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/93_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/93_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/95_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/95_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/DJI_0248-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/DJI_0248-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/DJI_0281-min.jpg',
	thumb_path: '/images/thumbnails/Alaska/DJI_0281-min.jpg'
} , 
{
	path: '/images/compressed/Alaska/IMG_4659_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/IMG_4659_min.jpg'
} , 
{
	path: '/images/compressed/Alaska/IMG_4733_min.jpg',
	thumb_path: '/images/thumbnails/Alaska/IMG_4733_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/a_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/a_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/b_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/b_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/c_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/c_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/d_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/d_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/e_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/e_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/f_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/f_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/g_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/g_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/h_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/h_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/i_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/i_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/j_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/j_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/k_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/k_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/l_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/l_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/m_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/m_min.jpg'
} , 
{
	path: '/images/compressed/Berkeley/n_min.jpg',
	thumb_path: '/images/thumbnails/Berkeley/n_min.jpg'
} , 
{
	path: '/images/compressed/Dubai/1-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/1-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/2-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/2-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/3-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/3-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/5-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/5-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00793-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00793-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00827-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00827-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00833-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00833-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00848-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00848-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00866-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00866-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00890-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00890-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00916-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00916-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/DSC00952-plate-removed-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/DSC00952-plate-removed-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/IMG_6532-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/IMG_6532-min.jpg'
} , 
{
	path: '/images/compressed/Dubai/IMG_6944-min.jpg',
	thumb_path: '/images/thumbnails/Dubai/IMG_6944-min.jpg'
} , 
{
	path: '/images/compressed/Pismo/a_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/a_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/b_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/b_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/c_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/c_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/d_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/d_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/e_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/e_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/f_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/f_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/g_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/g_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/h_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/h_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/i_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/i_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/j_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/j_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/k_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/k_min.jpg'
} , 
{
	path: '/images/compressed/Pismo/l_min.jpg',
	thumb_path: '/images/thumbnails/Pismo/l_min.jpg'
} , 
{
	path: '/images/compressed/India/1-min.jpg',
	thumb_path: '/images/thumbnails/India/1-min.jpg'
} , 
{
	path: '/images/compressed/India/2-min.jpg',
	thumb_path: '/images/thumbnails/India/2-min.jpg'
} , 
{
	path: '/images/compressed/India/3-min.jpg',
	thumb_path: '/images/thumbnails/India/3-min.jpg'
} , 
{
	path: '/images/compressed/India/4-min.jpg',
	thumb_path: '/images/thumbnails/India/4-min.jpg'
} , 
{
	path: '/images/compressed/India/5-min.jpg',
	thumb_path: '/images/thumbnails/India/5-min.jpg'
} , 
{
	path: '/images/compressed/India/6-min.jpg',
	thumb_path: '/images/thumbnails/India/6-min.jpg'
} , 
{
	path: '/images/compressed/India/DSC00986-min.jpg',
	thumb_path: '/images/thumbnails/India/DSC00986-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6684-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6684-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6773-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6773-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6778-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6778-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6788-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6788-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6817-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6817-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6826-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6826-min.jpg'
} , 
{
	path: '/images/compressed/India/IMG_6836-min.jpg',
	thumb_path: '/images/thumbnails/India/IMG_6836-min.jpg'
} , 
{
	path: '/images/compressed/India/z-min.jpg',
	thumb_path: '/images/thumbnails/India/z-min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/a_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/a_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/b_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/b_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/c_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/c_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/d_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/d_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/e_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/e_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/eo_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/eo_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/f1_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/f1_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/g_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/g_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/h_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/h_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/i_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/i_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/j_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/j_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/l_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/l_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/m2_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/m2_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/m_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/m_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/n_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/n_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/p_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/p_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/r_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/r_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/s_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/s_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/z1_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/z1_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/z2_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/z2_min.jpg'
} , 
{
	path: '/images/compressed/Yosemite/z_min.jpg',
	thumb_path: '/images/thumbnails/Yosemite/z_min.jpg'
} , 
]; 

export default images;